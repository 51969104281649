import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers, Request, RequestMethod } from '@angular/http';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class ApiUrlService {
  
  constructor(private http: Http) { }
  
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
  
  headers = new Headers();
  mainUrl = "";
  requestoptions = new RequestOptions();
  
  PostRequest(url, postData) {
    this.mainUrl = url;
    let formData: FormData = new FormData();
    if (postData !== "" && postData !== undefined && postData !== null) {
      for (var property in postData) {
        if (postData.hasOwnProperty(property)) {
          formData.append(property, postData[property]);
        }
      }
    }
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Post,
      url: this.mainUrl,
      headers: this.headers,
      body: formData
    })
    return this.http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          return res.json();
        }
      })
      .catch(this.handleError);
  }
  
  GetRequest(url) {
    this.headers = new Headers();
    this.mainUrl = url;
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Get,
      url: this.mainUrl
    })
    return this.http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          return res.json();
        }
      })
      .catch(this.handleError);
  }
  
  PostRequestWithMedia(url, postData, file) {
    this.mainUrl = url;
    let formData: FormData = new FormData();
    formData.append("file", file);
    if (postData !== "" && postData !== undefined && postData !== null) {
      for (var property in postData) {
        if (postData.hasOwnProperty(property)) {
          formData.append(property, postData[property]);
        }
      }
    }
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Post,
      url: this.mainUrl,
      headers: this.headers,
      body: formData
    })
    return this.http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          return res.json();
        }
      })
      .catch(this.handleError);
  }
  
  PostRequestWithJSON(url, postData) {
    this.mainUrl = url;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let jsonData = JSON.stringify(postData);
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Post,
      url: this.mainUrl,
      headers: this.headers,
      body: jsonData
    })
    return this.http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          return res.json()
        }
      })
      .catch(this.handleError);
  }
  
  GetRequestText(url) {
    this.headers = new Headers();
    this.mainUrl = url;
    this.requestoptions = new RequestOptions({
      method: RequestMethod.Get,
      url: this.mainUrl
    })
    return this.http.request(new Request(this.requestoptions))
      .map((res: Response) => {
        if (res) {
          return res.text();
        }
      })
      .catch(this.handleError);
  }
}
